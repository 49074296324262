import {useEffect} from 'react'

import {useReservationDetailsStorage} from '../storage'
import {useGetLayoutSize} from '../../../components-shared/LayoutSizeProvider'

export const useHooks = () => {
  const {reservation, isManualApproval, getCurrentMember, currentMember} =
    useReservationDetailsStorage()
  const {layoutSize} = useGetLayoutSize()

  useEffect(() => {
    getCurrentMember()
  }, [getCurrentMember])

  return {reservation, isManualApproval, layoutSize, currentMember}
}
