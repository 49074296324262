import React, {FC} from 'react'
import {WidgetProps} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/cssVars'
import {useSettings, useStyles} from '@wix/tpa-settings/react'

import {CountdownTimer} from '../components/CountdownTimer'
import {ReservationDetailsSection} from '../components/ReservationDetailsSection'
import {Form} from '../components/Form'
import {withReservationDetailsStorageProvider} from '../storage'
import {ErrorModal} from '../components/ErrorModal'
import {stylesParams} from '../stylesParams'
import {settingsParams} from '../settingsParams'
import {RtlProvider} from '../../../components-shared/RtlProvider'
import {LayoutSize} from '../../../components-shared/LayoutSizeProvider/types'
import {withLayoutSizeProvider} from '../../../components-shared/LayoutSizeProvider'

import {useHooks} from './useHooks'
import {classes, st} from './Widget.st.css'
import {LAYOUT_BREAKPOINTS_START, reservationDetailsDataHooks} from './constants'

const Widget: FC<WidgetProps> = () => {
  const {reservation, isManualApproval, layoutSize, currentMember} = useHooks()
  const isAccordionLayout = layoutSize === LayoutSize.XS || layoutSize === LayoutSize.S
  const styles = useStyles()
  const settings = useSettings()

  const titleTag =
    styles.get(stylesParams.titleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.titleFont).htmlTag

  const titleText = settings.get(
    isManualApproval ? settingsParams.titleRequestText : settingsParams.titleText,
  )

  if (!reservation?.id && !isManualApproval) {
    return <ErrorModal />
  }

  const title = (
    <Text className={classes.title} tagName={titleTag} aria-flowto="tr-timer">
      {titleText}
    </Text>
  )

  return (
    <RtlProvider>
      <div
        className={st(classes.root, {[layoutSize]: true})}
        data-hook={reservationDetailsDataHooks.root()}
      >
        <div className={classes.contentWrapper}>
          {isAccordionLayout ? title : null}
          <div className={classes.formWrap}>
            {isAccordionLayout ? null : title}
            <Form layoutSize={layoutSize} currentMember={currentMember} />
          </div>

          <div className={classes.detailsWrap}>
            {reservation ? <CountdownTimer layoutSize={layoutSize} /> : null}

            <ReservationDetailsSection layoutSize={layoutSize} />
            <ErrorModal />
          </div>
        </div>
      </div>
    </RtlProvider>
  )
}

export default withLayoutSizeProvider(
  withReservationDetailsStorageProvider(Widget),
  LAYOUT_BREAKPOINTS_START,
)
